@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root { */
/* --foreground-rgb: 37, 36, 35; */
/* --background-rgb: 255, 254, 252; */
/* } */

body {
  background-color: #fffefc;
  color: var(--thm-paragraph-color);
  font-family: var(--thm-font-family2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.google-map-container {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--thm-font-family1);
  color: var(--thm-headings-color);
}

h1 {
  font-size: var(--thm-h1);
  line-height: 1.3;
}

h2 {
  font-size: var(--thm-h2);
  line-height: 1.25;
}

h3 {
  font-size: var(--thm-h3);
  line-height: 1.20;
}

h4 {
  font-size: var(--thm-h4);
  line-height: 1.20;
}

h5 {
  font-size: var(--thm-h5);
  line-height: 1.15;
}

h6 {
  font-size: var(--thm-h6);
  line-height: 1.1;
}

p {
  font-size: var(--thm-p4);
}

small {
  font-size: var(--thm-p6);
}

.list-disc ul {
  list-style-type: disc;
  padding-left: 0.5rem;
}

@media (max-width: 768px) {
  html {
    font-size: 0.875rem;
  }
}